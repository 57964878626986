import React from "react";
import { Link } from "gatsby";

import "./index.scss";

const ServicesShortcutL = ({ img, name, link }) => {
	const displayImg = (img) => {
		return `services-${img}`;
	};

	return (
		<div className="services-shortcut-l">
			<img
				src={require(`../../images/${displayImg(img)}.svg`).default}
				alt={name}
				className="services-shortcut-img"
			/>
			<p className="services-shortcut-name">{name}</p>
			<Link to={link} className="services-shortcut-link">
				En savoir +
			</Link>
		</div>
	);
};

export default ServicesShortcutL;
