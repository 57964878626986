import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ServicesShortcutContainer from "../components/servicesShortcutContainer";
import "../scss/services.scss";

const VosBesoins = () => {
	return (
		<Layout>
			<Seo title="Vos besoins" />
			<div className="services-header">
				<h1 className="services-header-title">NOS SERVICES</h1>
				<div className="services-header-overview-container">
					<p className="services-header-overview">
						La Coopérative Libre Informatique est une association à but non lucratif
						spécialisée dans le numérique, fournissant une large variété de prestations.
					</p>
				</div>
			</div>
			<ServicesShortcutContainer />
			<div className="services-sections">
				<section id="web-dev">
					<h2 className="services-section-name">Développement web</h2>
					<p className="services-section-paragraph">
						Vous êtes une personne, une association, une entreprise et vous souhaitez{" "}
						<span>une présence sur internet</span> sans trop savoir comment vous y
						prendre ? Un projet en tête mais aucune idée du bout par lequel commencer ?
						Nous répondons présent·es pour <span>vous accompagner</span> dans la mise en
						place de votre projet de site web, quel qu'il soit, et nous occupons de
						tout, du développement à la mise en ligne.
					</p>
				</section>
				<section id="software-dev">
					<h2 className="services-section-name">Développement logiciel</h2>
					<p className="services-section-paragraph">
						Vous avez des besoins spécifiques au sein de votre entreprise ou de votre
						administration ? Nous développons également des{" "}
						<span>logiciels sur mesure</span> pour répondre à chacun de vos besoins. Que
						vous cherchiez une <span>solution de gestion administrative</span>, de
						<span> traitement de données</span> ou encore à vous équiper d'une{" "}
						<span>application mobile</span>, nous saurons nous adapter pour développer
						la solution qui vous correspond.
					</p>
				</section>
				<section id="maintenance">
					<h2 className="services-section-name">Maintenance</h2>
					<p className="services-section-paragraph">
						Un problème avec <span>votre ordinateur, ou votre réseau</span> ? Nous y
						jetons un oeil pour trouver l'origine de la panne et la résoudre.
					</p>
					<p className="services-section-paragraph">
						Votre site internet ou un de vos logiciels présente des problèmes de
						fonctionnement ? Après diagnostique,{" "}
						<span>nous pouvons nous charger de les réparer</span>. En outre, garder à
						jour un site internet ou un logiciel permet de se prémunir d'une majorité de
						problèmes : pertes de données, bugs, piratage, entre autres.
					</p>
				</section>
				<section id="training">
					<h2 className="services-section-name">Formations</h2>
					<p className="services-section-paragraph">
						Nous proposons des formations concernant de nombreux domaines. Elles sont
						<span>
							{" "}
							destinées aux entreprises ou à toute personne désireuse de compléter ses
							savoirs
						</span>
						.
					</p>
					<ul className="services-formation-list">
						<li>
							Usage d'un <i className="italic">Content Manager System</i> comme
							Wordpress
						</li>
						<li>Outils de bureautique</li>
						<li>Administration de systèmes Linux : usage, configuration,</li>
						<li>Développement opérationnel</li>
					</ul>
				</section>
				<section id="self-hosting">
					<h2 className="services-section-name">Autohébergement</h2>
					<p className="services-section-paragraph">
						Les hébergement centralisés présentent de nombreux risques : les accidents
						se répercutent sur l'ensemble des services numérique.{" "}
						<span>
							Hébergez chez vous l'ensemble des services internet que vous utilisez au
							quotidien
						</span>{" "}
						: traitement de texte participatif, sauvegarde dans le cloud, email, agenda
						... Vous résolvez de la sorte la majeure partie des problèmes de la
						centralisation : propriété des données, sauvegardes, gestion des données
						sensibles, décentralisation de services de secours ou d'urgence. Cette
						politique vous permet une indépendance et une autonomie de vos données. Que
						vous soyez particulier, ou professionnel·le, vous gagnez en sécurité à
						héberger vos propres services numériques.
					</p>
				</section>
				<section id="security-audit">
					<h2 className="services-section-name">Audit de sécurité</h2>
					<p className="services-section-paragraph">
						Les réglementations française et européenne accompagnent et contraignent les
						infrastructures numériques à recourir à de bonnes pratiques de sécurité.
						<span>
							{" "}
							Notre démarche est de déployer et de transmettre les compétences
							techniques et juridiques et afin de vous rendre résilient.
						</span>{" "}
						Il s'agit de savoir quelles données sont nécessaires, à qui, dans quelles
						conditions, avec quels contrôles, le tout en permettant les exceptions (en
						cas d'incendies par exemple).{" "}
						<span>Nous vous aiderons à comprendre et limiter les failles</span> des
						réseaux de bureaux, d'industries, des serveurs ou des pratiques plus
						particulières, comme par exemple le{" "}
						<i className="italic">Bring Your Own Device</i> ou l'
						<i className="italic">Internet of Things</i>.
					</p>
				</section>
			</div>
		</Layout>
	);
};

export default VosBesoins;
