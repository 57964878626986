import React from "react";
import { Link } from "gatsby";

import "./index.scss";

const servicesShortcutS = ({ img, name, link }) => {
	const displayImg = (img) => {
		return `services-${img}-white`;
	};
	return (
		<div className="services-shortcut-s">
			<Link to={link} className="services-link-container">
				<img
					src={require(`../../images/${displayImg(img)}.svg`).default}
					alt={name}
					className="services-shortcut-img-s"
				/>
				<p className="services-shortcut-name-s">{name}</p>
			</Link>
		</div>
	);
};

export default servicesShortcutS;
