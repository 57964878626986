import React from "react";

import "./index.scss";
import useMediaQuery from "../../hooks/useMediaQuery";
import ServicesShortcutL from "../servicesShortcutL";
import ServicesShortcutS from "../servicesShortcutS";

const ServicesShortcutContainer = () => {
	const isDesktop = useMediaQuery("(min-width: 720px)");

	const namesArr = [
		`Développement web`,
		`Développement logiciel`,
		`Maintenance`,
		`Formations`,
		`Autohébergement`,
		`Audit de sécurité`,
	];

	const shortsArr = [
		"web-dev",
		"software-dev",
		"maintenance",
		"training",
		"self-hosting",
		"security-audit",
	];

	const hydratedComponents = [];

	const handleProps = (names, shorts) => {
		names.forEach((name, i) => {
			const short = shorts[i];
			const link = `#${short}`;
			hydratedComponents.push(
				isDesktop ? (
					<ServicesShortcutL key={i} img={short} name={name} link={link} />
				) : (
					<ServicesShortcutS key={i} img={short} name={name} link={link} />
				)
			);
		});
	};

	const display = () => {
		handleProps(namesArr, shortsArr);
		return hydratedComponents;
	};

	return <div className="services-shortcuts-container">{display()}</div>;
};

export default ServicesShortcutContainer;
